<template>
  <div class="collect_info">

    <mt-header fixed :title="getTitle(querytype)+'报告详情'">
<!--      <mt-button slot="left" style=" width: 90px"-->
<!--        @click="back()"      icon="back">返回</mt-button>-->

<!--      <mt-button slot="right" style=" width: 90px" @click="download()">下载</mt-button>-->
<!--      <a href="rpt/abc.pdf" slot="right" download>{{this.pdfInfo.url}}</a>-->
    </mt-header>
      <div class="pdf" v-show="fileType === 'pdf'">
        <p class="arrow">
          <!--      // 上一页-->
          <span @click="changePdfPage(0)" class="turn" :class="{grey: currentPage===1}">上一页</span>
          {{currentPage}} / {{pageCount}}
          <!--      // 下一页-->
          <span @click="changePdfPage(1)" class="turn" :class="{grey: currentPage===pageCount}">下一页</span>
        </p>
        <!--    // 自己引入就可以使用,这里我的需求是做了分页功能,如果不需要分页功能,只要src就可以了-->
        <!--    // src需要展示的PDF地址-->
        <!--    // 当前展示的PDF页码-->
        <!--    // PDF文件总页码-->
        <!--    // 一开始加载的页面-->
        <!--    // 加载事件-->
        <pdf :src="src" :page="currentPage"
             @num-pages="pageCount=$event"
             @page-loaded="currentPage=$event"
             @loaded="loadPdfHandler">
        </pdf>
        <p class="arrow">
          <!--      // 上一页-->
          <span @click="changePdfPage(0)" class="turn" :class="{grey: currentPage===1}">上一页</span>
          {{currentPage}} / {{pageCount}}
          <!--      // 下一页-->
          <span @click="changePdfPage(1)" class="turn" :class="{grey: currentPage===pageCount}">下一页</span>
        </p>
      </div>



  </div>
</template>

<script>
import pdf from 'vue-pdf'
//import BScroll from 'better-scroll'
export default {
  metaInfo: {
    meta: [
      { name: 'viewport', content: 'width=device-width,initial-scale=1.0, maximum-scale=2.0, user-scalable=yes' }
    ]
  },
  components: {pdf},
  data () {
    return {
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数

      querytype:'',
      fileType: 'pdf', // 文件类型
      src: '', // pdf文件地址
      numPages: 0, // 总页数
     // pdfScroll: null,
    }
  },
  computed: {
    pdfInfo () { // 路由携带的参数，包括pdf链接
      //return
      return    {
        url:location.protocol+"//"+location.host+ "/rpt/"+this.$route.query.filename
            //'//this.$route.query.url

        ,type:this.$route.query.type,filename:this.$route.query.filename
      }
    }

  },

created () {
  // 有时PDF文件地址会出现跨域的情况,这里最好处理一下
  this.querytype=this.pdfInfo.type;
  this.src = pdf.createLoadingTask(this.pdfInfo.url)
},


methods: {
  getTitle(stype) {
    let sRet = ''
    if (stype == 1) {
      sRet = "两癌筛查";
    } else if (stype == 2) {
      sRet = "孕优";
    } else if (stype == 3) {
      sRet = "婚检";
    } else if (stype == 4) {
      sRet = "产检";
    }
    return sRet;
  },
download(){//
  this.downloadPDF (this.pdfInfo.url, this.pdfInfo.filename);

},
  downloadPDF (url/*,fileName*/) {

  console.log(url)
    var src = url;
    var form = document.createElement('form');
    form.action = src;
    document.getElementsByTagName('body')[0].appendChild(form);
    form.submit();
    return ;
   /* this.$axios.get(url, {   //静态资源文件夹public
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      let fname = fileName;
      link.href = url;
      link.setAttribute('download', fname);
      document.body.appendChild(link);
      link.click();
    }).catch(error => {
      console.log('error:'+JSON.stringify(error))
    });*/
    /*
        fetch(url).then(function (response) {
            console.log(JSON.stringify(response))
          if (response.ok) {
            return response.arrayBuffer()
          }
          throw new Error('Network response was not ok.')
        }).then(function (arraybuffer) {
          let blob = new Blob([arraybuffer], {
            type: `application/pdf;charset-UTF-8` // word文档为msword,pdf文档为pdf
          })
          let objectURL = URL.createObjectURL(blob)
          let downEle = document.createElement('a')
          let fname = fileName // 下载文件的名字
          // let fname = `download` // 下载文件的名字
          downEle.href = objectURL
          downEle.setAttribute('download', fname)
          document.body.appendChild(downEle)
          downEle.click()

        })*/
  },

  back() {
    this.$router.go(-1);
  },
  returnBack () {
    this.$router.push({name: 'myDownLoad'})
  },
  // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
  changePdfPage (val) {
    // console.log(val)
    if (val === 0 && this.currentPage > 1) {
      this.currentPage--
      // console.log(this.currentPage)
    }
    if (val === 1 && this.currentPage < this.pageCount) {
      this.currentPage++
      // console.log(this.currentPage)
    }
  },
  // pdf加载时
  loadPdfHandler () {
    this.currentPage = 1 // 加载的时候先加载第一页
  }
}
}

</script>

<style lang="scss" scoped>
.collect_info {
  height: 100%;
  display: flex;
  flex-direction: column;
  /*background: #f8f8f8;*/
  position: relative;
}
.score_header {
  text-align: center;
  letter-spacing: 0.2em;
  position: relative;
  font-size: 18px;
  width: 100%;
  //background-color: rgb(0, 115, 231);
  color: #fff;
  height: 45px;
  line-height: 45px;
  flex: none;
  z-index: 1;
}
.return__icon{
  position: absolute;
  margin-left: 15px;
}
.iconfanhui {
  margin-top: 10px;
  font-size: 20px;
}
.pdf {
  text-align: center;
  margin-top: 30px;
  .arrow {
    margin: 8px 0;
    /*font-size: 14px;*/
  }
  .grey {
    color: #9c9c9c;
  }
  .mint-header {
    font-size: 18px;
    background-color: #eb89a9;
  }
}
</style>